












































import { Component, Vue } from 'vue-property-decorator'
import { getAllMachine, commitOffMic } from '@/pages/videoGame/api'
@Component
export default class extends Vue {
  showPop = false
  formInfo = {
    offMicPassword: '',
    seatId: ''
  }
  list: any = []
  handleOpen(seat: any) {
    if (seat.userConnectionStatus == 1) {
      this.formInfo.seatId = seat.seatId
      this.showPop = true
    }
  }
  async handleConfirm() {
    const params = {
      ...this.formInfo
    }
    const { data } = await commitOffMic(params)
    this.$toast('下机成功,请手动刷新')
  }
  handleClose() {
    this.formInfo.offMicPassword = ''
    this.formInfo.seatId = ''
  }
  async getData() {
    const { data } = await getAllMachine()
    this.list = data
  }
  mounted() {
    this.getData()
  }
}
